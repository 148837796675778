import * as React from 'react';
import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import classNames from 'classnames';
import { navigate } from 'gatsby-link';
import { Required } from '../ui/Required';
import { Input } from '../ui/Input';
import { Textarea } from '../ui/Textarea';
import { FormButton } from '../ui/FormButton';
import { PrivacyLink } from '../ui/PrivacyLink';
import ErrorText from '../ui/ErrorText';
import { ContactFormData } from '../../types/ContactFormData';

type ContactFormProps = {
  className?: string;
};

const ContactForm: React.VFC<ContactFormProps> = ({ className }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactFormData>();
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);

  const onSubmit: SubmitHandler<ContactFormData> = async (data, e) => {
    e?.preventDefault();

    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = {
      blocks: [
        {
          type: 'header',
          text: {
            type: 'plain_text',
            text: 'FDM LPからの新規お問い合わせ:thumbsup:',
            emoji: true,
          },
        },
        {
          type: 'section',
          fields: [
            {
              type: 'mrkdwn',
              text: `*お名前:*\n${data.name}`,
            },
            {
              type: 'mrkdwn',
              text: `*お名前（フリガナ）:*\n${data.kana}`,
            },
          ],
        },
        {
          type: 'section',
          fields: [
            {
              type: 'mrkdwn',
              text: `*店舗名:*\n${data.shopName}`,
            },
            {
              type: 'mrkdwn',
              text: `*会社名:*\n${data.companyName}`,
            },
          ],
        },
        {
          type: 'section',
          fields: [
            {
              type: 'mrkdwn',
              text: `*メールアドレス:*\n${data.email}`,
            },
          ],
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `*お問い合わせ内容:*\n${data.contents}`,
          },
        },
      ],
    };

    await fetch(
      'https://hooks.slack.com/services/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',
      {
        method,
        mode: 'no-cors',
        headers,
        body: JSON.stringify(body),
      }
    )
      .then(() => navigate('/contact/thanks/'))
      .catch(console.error);
  };
  const confirm: SubmitHandler<ContactFormData> = () => {
    setIsReadOnly(true);
  };
  const turnBack = () => {
    setIsReadOnly(false);
  };

  return (
    <div
      className={classNames(
        'py-10 px-5 md:p-10 bg-white rounded-lg shadow-sm',
        className
      )}
    >
      <p className="text-sm tracking-wide leading-loose text-center">
        必要項目 <span className="text-warn">※</span>{' '}
        をご記入の上、お進みください
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <dl className="mt-8">
          <dt className="mt-5 text-sm">
            お名前
            <Required />
          </dt>
          <dd className="mt-3 relative">
            <Input
              placeholder="例）山田　太郎"
              name="name"
              register={register}
              required
              readOnly={isReadOnly}
              error={!!errors.name?.type}
            />
            {errors.name?.type === 'required' && (
              <ErrorText className="absolute left-1/2 transform -translate-x-1/2 bottom-4">
                このフィールドは必須です
              </ErrorText>
            )}
          </dd>
          <dt className="mt-5 text-sm">
            お名前（フリガナ）
            <Required />
          </dt>
          <dd className="mt-3 relative">
            <Input
              placeholder="例）ヤマダ　タロウ"
              name="kana"
              register={register}
              required
              readOnly={isReadOnly}
              error={!!errors.kana?.type}
            />
            {errors.kana?.type === 'required' && (
              <ErrorText className="absolute left-1/2 transform -translate-x-1/2 bottom-4">
                このフィールドは必須です
              </ErrorText>
            )}
          </dd>
          <dt className="mt-5 text-sm">
            店舗名
            <Required />
          </dt>
          <dd className="mt-3 relative">
            <Input
              placeholder="例）フードデリバリー品川店"
              name="shopName"
              register={register}
              required
              readOnly={isReadOnly}
              error={!!errors.shopName?.type}
            />
            {errors.shopName?.type === 'required' && (
              <ErrorText className="absolute left-1/2 transform -translate-x-1/2 bottom-4">
                このフィールドは必須です
              </ErrorText>
            )}
          </dd>
          <dt className="mt-5 text-sm">会社名</dt>
          <dd className="mt-3">
            <Input
              placeholder="例）株式会社フードデリバリー"
              name="companyName"
              register={register}
              required={false}
              readOnly={isReadOnly}
            />
          </dd>
          <dt className="mt-5 text-sm">
            メールアドレス
            <Required />
          </dt>
          <dd className="mt-3 relative">
            <Input
              type="email"
              placeholder="例）example@delivery.co.jp"
              name="email"
              register={register}
              required
              readOnly={isReadOnly}
              error={!!errors.email?.type}
            />
            {errors.email?.type === 'required' && (
              <ErrorText className="absolute left-1/2 transform -translate-x-1/2 bottom-4">
                このフィールドは必須です
              </ErrorText>
            )}
          </dd>
          <dt className="mt-5 text-sm">
            お問い合わせ内容
            <Required />
          </dt>
          <dd className="mt-3 relative">
            <Textarea
              placeholder="お問い合わせ内容をご記入ください"
              name="contents"
              register={register}
              required
              readOnly={isReadOnly}
              error={!!errors.contents?.type}
            />
            {errors.contents?.type === 'required' && (
              <ErrorText className="absolute left-1/2 transform -translate-x-1/2 bottom-4">
                このフィールドは必須です
              </ErrorText>
            )}
          </dd>
        </dl>
        <PrivacyLink className="mt-7.5" />
        <div className="mt-7.5 mx-auto max-w-xxs md:max-w-none text-center">
          {!isReadOnly ? (
            <FormButton
              color="primary"
              onClick={handleSubmit(confirm)}
              className="w-full max-w-xxs"
            >
              入力内容を確認する
            </FormButton>
          ) : (
            <>
              <FormButton
                color="primary"
                onClick={handleSubmit(onSubmit)}
                className="w-full max-w-xxs"
              >
                送信する
              </FormButton>
              <FormButton
                color="gray"
                onClick={turnBack}
                className="mt-3.5 md:mt-0 md:ml-5 w-full max-w-xxs"
              >
                入力画面に戻る
              </FormButton>
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
