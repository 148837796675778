import classNames from 'classnames';
import * as React from 'react';
import { Path, UseFormRegister } from 'react-hook-form';
import { ContactFormData } from '../../types/ContactFormData';

type TextareaPropsType = {
  className?: string;
  placeholder?: string;
  name: Path<ContactFormData>;
  readOnly?: boolean;
  error?: boolean;
  register: UseFormRegister<ContactFormData>;
  required: boolean;
}

export const Textarea: React.VFC<TextareaPropsType> = ({className, name, placeholder, readOnly = false, error = false, register, required}) => (
  <div className={classNames('', className)}>
    <textarea
      rows={6}
      placeholder={placeholder}
      readOnly={readOnly}
      className={classNames(
        'p-3.75 border border-gray-lightest block w-full sm:text-sm rounded-sm placeholder-gray-400',
        error ? 'border-warn' : 'border-gray-lightest'
      )}
      {...register(name, { required })}
    />
  </div>
);
