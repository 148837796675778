import * as React from 'react';
import Layout from '../components/common/Layout';
import MainContents from '../components/common/MainContents';
import PageHeading from '../components/ui/Pageheading';
import ContactForm from '../components/contact/ContactForm';

const ContactPage = () => (
    <Layout pageTitle="導入前 お問い合わせ | FDM フードデリバリーマネージャー">
      <MainContents>
        <PageHeading>導入前 お問い合わせ</PageHeading>
        <p className="mt-7.5 text-sm tracking-wide leading-loose md:text-center">内容確認後、担当より通常1〜3営業日以内にご連絡いたします</p>
        <ContactForm className="mt-7.5 md:mt-10 mx-auto max-w-screen-sm" />
        <p className="mt-5 mx-auto max-w-screen-sm text-xs leading-relaxed">
          フォームで送信できない場合は、必要項目をご記入の上、<span className="text-primary">info@lisatech.jp</span>までメールをお送りください
        </p>
      </MainContents>
    </Layout>
  );

export default ContactPage;
